import axios from "axios";
import { API_SERVER_URL } from "../../app/constants";

// For basic consent data (JSON)
export const markPatientConsent = async (formData: FormData) => {
    return axios.post(
        `${API_SERVER_URL}/rtm/mark_patient_consent`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
    );
};

