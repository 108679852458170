import React from 'react';
import styled from 'styled-components';

const ContractContainer = styled.div`
  text-align: left;
  padding: 20px;
  height: 60vh;
  overflow-y: scroll;
`;

const Section = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  
  p {
    margin-bottom: 12px;
    line-height: 1.4;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
`;

const SubTitle = styled.h3`
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px;
`;

const UpdateDate = styled.p`
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
  font-size: 13px;
`;

export function RtmContract({ allDetails }) {
  return (
    <ContractContainer>
      <Title>Athelas, Inc.<br />Patient Consent for<br />Care Management Program</Title>
      <UpdateDate>Updated January 15, 2025</UpdateDate>

      <Section>
        <p>
          This consent form ("Consent") reviews the benefits, risks and limitations of you, the patient, utilizing the
          remote monitoring ("Remote Monitoring") services (the "RPM/RTM et. al. Services") provided by
          Athelas, Inc. (referred to as "Athelas", "we", "us" or "our") at the direction of your healthcare provider. It
          also explains how your information will be used after performance of the Remote Monitoring Services.
        </p>
        <p>
          You will not receive Remote Monitoring Services unless you confirm that you have read and understood
          the contents of this form.
        </p>
        <p>
          By clicking on the box, you indicate that this Consent is a binding agreement and that you have read
          and understood the following terms. Capitalized terms used but not defined in this Consent have the
          meaning given to them in our other policies, including our Terms of Use (athelas.com/terms-of-use)
          and Privacy Policy (athelas.com/privacy-policy), which are incorporated by reference into this Consent.
          By clicking your acceptance, you have chosen to use our Remote Monitoring Services.
        </p>
      </Section>

      <Section>
        <SubTitle>1. Consent to Remote Monitoring Services</SubTitle>
        <p>
          Our Remote Monitoring Services are offered and available to users who are residents of the United
          States. Remote Monitoring covers a suite of services that may be ordered by your doctor individually or
          in combination, including Remote Physiologic Monitoring ("RPM"), Remote Patient Monitoring, Chronic
          Care Management ("CCM"), Principal Care Management ("PCM"), Collaborative Care Management
          (CoCM), Remote Therapeutic Monitoring (RTM) or another care management regime deemed appropriate
          by your healthcare provider, each of which is referred to generically herein as Remote Monitoring. By
          agreeing to receive Remote Monitoring, you agree to receive one or more of these services at the
          discretion of your doctor. Your use of Athelas's Remote Monitoring Services is voluntary. It is your choice
          whether to utilize our Remote Monitoring Services or not. Prior to signing this Consent, you may wish to
          speak with your healthcare provider for further guidance about our Remote Monitoring Services.
        </p>
      </Section>

      <Section>
        <SubTitle>2. Procedures and Protocol</SubTitle>
        <p>
          When you sign up for Remote Monitoring Services, we will collect some information from you. We
          cannot perform the Remote Monitoring Services without collecting information from you, but we will
          only collect information that will assist us in providing the Remote Monitoring Services that you have
          requested. In order for the Remote Monitoring Services to be provided as intended, you must provide
          accurate and correct information. Remote Monitoring Services will not be provided to you unless
          authorized by your Healthcare Provider.
        </p>
        <p>
          The results of our Remote Monitoring Services, and other communications, will be made available to you
          either through our site, or via text message (SMS) or email. If messages are sent via SMS, standard
          messaging rates apply and you can opt out at any time by texting STOP. You authorize this
          communication by Athelas. Regardless of the method of communication, you acknowledge that you may
          terminate this authorization at any time by emailing privacy@athelas.com, but understand that doing so
          may Result in a termination of Remote Monitoring Services by Athelas.
        </p>
      </Section>

      <Section>
        <SubTitle>3. Clinical Review</SubTitle>
        <p>
          Athelas clinical staff ("Clinicians"), under the general supervision of your healthcare provider, may
          undertake clinical review of your Remote Monitoring program. As part of that review, Clinicians will
          reach out to you no less than monthly to discuss, via phone or video chat, your ongoing Remote
          Monitoring Services, to provide recommendations, or to suggest you seek the advice of your healthcare
          provider. You agree that Clinicians may contact you via the methods set forth above, that you will
          respond to any voicemail left by a Clinician promptly in order to discuss your Remote Monitoring
          program, and that you will update your contact information with Athelas if it changes after the initiation
          of the Remote Monitoring program. You acknowledge that you may terminate this authorization at any
          time by emailing privacy@athelas.com, but understand that doing so may result in a termination of
          Remote Monitoring Services by Athelas.
        </p>
      </Section>

      <Section>
        <SubTitle>4. Limitations on Use of Remote Monitoring Program</SubTitle>
        <p>
          You acknowledge that the Athelas Remote Patient Monitoring Services are intended only to track a
          patient's health over time, not to identify or prevent acute health episodes. In the event that you feel
          unwell you should seek immediate medical attention. You should not ignore symptoms of a potentially
          serious health condition due to the fact that your provider or Athelas clinical staff have not informed you
          there is an issue.
        </p>
      </Section>

      <Section>
        <SubTitle>5. Remote Monitoring Devices</SubTitle>
        <p>
          Some Remote Monitoring services require the use of a device. Any Remote Monitoring devices (the
          "Devices") you receive from Athelas are provided solely for the purpose of providing Remote Monitoring
          Services and remain Athelas's Property. You agree to not give the devices to another person without
          Athelas's written consent. If you cease using the Devices, you agree that you will, at Athelas's direction,
          either dispose of, donate, or return the devices to Athelas.
        </p>
      </Section>

      <Section>
        <SubTitle>6. Access to Athelas Portal</SubTitle>
        <p>
          As part of Remote Monitoring Services, Athelas may provide you with access to a patient-facing online
          portal where you can review Remote Monitoring results. If you do so, you agree to follow the Athelas
          Privacy Policy (athelas.com/privacy-policy) and Terms of Use (athelas.com/terms-of-use), which are
          incorporated by reference into this Consent.
        </p>
      </Section>

      <Section>
        <SubTitle>7. Patient Responsibility</SubTitle>
        <p>
          You may be responsible for the payment of a copay, coinsurance, or other costs resulting from
          application of your insurance plan's deductible (collectively, "Patient Responsibility") if you receive
          Remote Monitoring services. By consenting to receive Remote Monitoring Services, you acknowledge,
          accept, and agree to pay promptly all Patient Responsibility resulting from the Remote Monitoring
          Services.
        </p>
      </Section>

      <Section>
        <SubTitle>8. Risks and Benefits</SubTitle>
        <p>
          Utilizing our Remote Monitoring Services can help you learn more about your health. You may use this
          information to make more informed health care decisions and choices. Disclosing certain information
          may make you uncomfortable. Our Remote Monitoring Services may reveal sensitive information about
          your health. This information may be distressing.
        </p>
        <p>
          Health information received by your healthcare providers may become part of your medical record and
          through that route may be accessible to other healthcare providers and/or insurance companies in the
          future. Information that you share with family, friends or employers may also be used against your
          interests. Even if you share health information that has no or limited meaning today, that information
          could have greater meaning in the future as new discoveries are made.
        </p>
        <p>
          Athelas, or the Healthcare Provider who reviews your results, may advise you to have a follow-up visit
          with your doctor after reviewing the results of your Remote Monitoring Services. As a result, you may
          learn about health conditions and problems or potential health risks that you were not aware of before
          you utilized our Remote Monitoring Services. You may experience stress, anxiety, or emotional or
          physical discomfort when you learn about health problems or potential health problems. There may also
          be additional risks of utilizing our Remote Monitoring Services that are currently unforeseeable.
        </p>
      </Section>

      <Section>
        <SubTitle>9. Limitations on Remote Monitoring Services</SubTitle>
        <p>
          OUR SERVICES DO NOT PROVIDE MEDICAL ADVICE AND SHOULD ONLY BE USED BY A LICENSED
          HEALTHCARE PROVIDER TO RENDER CARE. The information and content provided are being gathered on
          behalf of your healthcare provider. Always seek the advice of your physician or other qualified healthcare
          provider with any questions you have regarding your medical care, and never disregard professional
          medical advice or delay seeking it because of information received as part of Remote Monitoring
          Services. Nothing contained in the Remote Monitoring Services is intended to constitute a medical
          diagnosis or treatment. By clicking your acceptance, you understand and agree that your results are not
          a substitute for professional medical advice.
        </p>
      </Section>

      <Section>
        <SubTitle>10. Record Retention</SubTitle>
        <p>
          We are subject to multiple laws on the retention of data. Accordingly, we retain any information
          collected about you for as long as we are required to maintain it for regulatory and compliance purposes
          or for a legal or business necessity.
        </p>
        <p>
          You understand that by utilizing Remote Monitoring Services, you acquire no rights in any research or
          commercial products that may be developed by Athelas or its collaborating partners. You specifically
          understand that you will not receive compensation for any research or commercial products that include
          or result from your sample or information.
        </p>
        <p>
          Your information and test results may be stored in a repository and used for validation, educational,
          and/or research purposes. By clicking your acceptance of this Consent, you acknowledge and agree that
          Athelas may de-identify the information that is obtained from our Remote Monitoring Services and that
          we may aggregate this information with de-identified information from other users. De-identification
          means that the personally identifiable information associated with your information will be removed.
        </p>
        <p>
          You understand that we will not disclose, sell and otherwise commercialize personally identifiable
          information but that we may disclose, sell and otherwise commercialize de-identified information and
          user content without restriction.
        </p>
        <p>
          We may incorporate different or additional technologies to test or analyze data in the future. We may, at
          our sole discretion, choose to analyze your data using such technologies. We are not obligated to notify
          you if we chose to conduct different or additional testing or analysis on your data. Your receipt of
          Remote Monitoring Services does not automatically include any such different or additional
          technologies.
        </p>
      </Section>

      <Section>
        <SubTitle>11. Confidentiality</SubTitle>
        <p>
          By clicking your acceptance of this Consent, you acknowledge that you have read and understand our
          Privacy Policy (athelas.com/privacy-policy). You agree the Athelas is not liable for the unauthorized
          release of your results or information unless such unauthorized release was the result of gross
          negligence or willful misconduct on the part of Athelas.
        </p>
      </Section>

      <Section>
        <SubTitle>12. Withdrawal of Consent</SubTitle>
        <p>
          Your use of Athelas's Remote Monitoring Services is voluntary. You may choose to withdraw your
          Consent or to stop using Athelas's Remote Monitoring Services at any time. Such requests should be sent
          to us by email at privacy@athelas.com.
        </p>
        <p>
          Please note that while any such requests you make will be reflected in our databases within a reasonable
          period of time, we may retain your information in the ordinary course of business, for the satisfaction of
          our legal obligations, or where we otherwise reasonably believe that we have a legitimate reason to do
          so. Information that has already been de-identified, anonymized, or aggregated may not be retrievable
          or traced back for destruction, deletion, or amendment.
        </p>
      </Section>

      <Section>
        <SubTitle>13. Legal Agreement</SubTitle>
        <p>
          You give permission for Athelas, its representatives, affiliates, staff, agents, and designees to perform the
          requested Remote Monitoring Services and to disclose your information and results to your healthcare
          provider and in accordance with our Privacy Policy.
        </p>
      </Section>
    </ContractContainer>
  );
} 