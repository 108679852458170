import { object, string, boolean } from "yup";

const requiredMessage = "This field is required."
const phoneRegExp = /^\d{10}$/;  // Exactly 10 digits

export const patientSchema = object().shape({
    first_name: string().required(requiredMessage),
    last_name: string().required(requiredMessage),
    date_of_birth: string().required(requiredMessage),
    email: string()
        .required(requiredMessage)
        .email("Please enter a valid email address")
        .matches(
            /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
            "Please enter a valid email address"
        )
        .trim(),
    phone_number: string()
        .required(requiredMessage)
        .matches(phoneRegExp, "Please enter a valid 10-digit phone number")
        .trim(),
    patient_consent: boolean()
        .required(requiredMessage)
        .oneOf([true], "You must agree to the patient consent terms"),
}).required();