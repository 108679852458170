import { useEffect, useState, useRef} from "react";
import axios from "axios";
import jwt from "jsonwebtoken";
import { PUBLIC_KEY, GGP_PAYPAL_CLIENT_ID, API_SERVER_URL } from "../../app/constants";
import { useLocation } from "react-router-dom";
import {  trackEventAthelasHome } from "../../app/utils/logging";
import styled from "styled-components";
import Modal from "react-modal";
import {Contract} from "./Contract"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Alert } from "@mui/material";


function AthelasHomeOnboarding() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const [showContractModal, setShowContractModal] = useState(false);
    const [token, setToken] = useState(null);
    const [decodedTokenState, setDecodedTokenState] = useState(null);
    const [allDetails, setAllDetails] = useState(null);
    const [agreeToContract, setAgreeToContract] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [numDevices, setNumDevices] = useState(0);
    const [numStrips, setNumStrips] = useState(0);
    const [patientID, setPatientID] = useState("");
    const [tokenFailed, setTokenFailed] = useState(false);
    const [devicePrice, setDevicePrice] = useState(0);
    const [stripPrice, setStripPrice] = useState(0);
    const [shipping_address, setShippingAddress] = useState('');
    const [orderIDPaypal, setOrderIDPaypal] = useState(false);
    const [errorMessagePaypal, setErrorMessagePaypal] = useState("");
    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
    const [paypalButtonHidden, setPaypalButtonHidden] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const numDevicesRef = useRef(0);
    const devicePriceRef = useRef(0);
    const numStripsRef = useRef(0);
    const stripPriceRef = useRef(0);
    const [isLoading, setIsLoading] = useState(true);
    const [backendMessages, setBackendMessages] = useState({
      message: "",
      associationsCreated: false,
      associationMessages: []
    });
    const [hasBackendError, setHasBackendError] = useState(false);
    const [isEditingAddress, setIsEditingAddress] = useState(false);
    const [addressFields, setAddressFields] = useState({
      address_line1: '',
      address_line2: '',
      city_locality: '',
      state_province: '',
      postal_code: '',
      country_code: 'US'
    });
    const [addressUpdateSuccess, setAddressUpdateSuccess] = useState(false);
    const [addressVerificationError, setAddressVerificationError] = useState("");
    const [isVerifyingAddress, setIsVerifyingAddress] = useState(false);
    const currentAddressRef = useRef(null);

  
  
    const modalStyles = {
      content: {
        outerWidth: "30%"
      },
    }

    function checkBlacklist(token) {
      const headers = {};
      axios.get(
        `${API_SERVER_URL}/athelas_one/is_token_blacklisted/${token}`,
        {},
        {
          headers,
        }
        )
        .then((response) => {
          setTokenFailed(false);
        })
        .catch((e) => {
          if (e.response.status === 401 && e.response.data === 'Unauthorized') {
            setTokenFailed(true);
          }
        });
    }

    function fetchAllOnboardingDetails(patientID) {
      const headers = {};
      headers['Authorization'] = 'Bearer ' + token;
      setIsLoading(true);
      axios.get(
        `${API_SERVER_URL}/ggp_prospective_patient/fetch_ggp_prospective_patient_details/${patientID}`,
        {},
        {
          headers,
        }
      )
      .then((response) => {
        const details = response.data.details
        setAllDetails(details)
        setDevicePrice(details.athelas_home_device_price)
        setStripPrice(details.athelas_home_strip_price)
        setNumStrips(details.num_strips)
        setNumDevices(details.num_devices)
        setShippingAddress(details.shipping_address)
        setPaymentMade(details.payment_made || false)
        numDevicesRef.current = details.num_devices;
        devicePriceRef.current = details.athelas_home_device_price;
        numStripsRef.current = details.num_strips;
        stripPriceRef.current = details.athelas_home_strip_price
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    }

    useEffect(() => {
        Modal.setAppElement('body')
        const tokenFromURL = query.get("token");
        checkBlacklist(tokenFromURL)
        if (!tokenFailed) {
          setToken(tokenFromURL)
          if (decodedTokenState === null) {
            try {
              const decodedToken = jwt.verify(tokenFromURL, PUBLIC_KEY, {
                  algorithms: ["RS256"],
              })
              setDecodedTokenState(decodedToken)
              setPatientID(decodedToken.patient_id)
              setUserEmail(decodedToken.user_email)
              fetchAllOnboardingDetails(decodedToken.patient_id)
            } catch(e) {
              setTokenFailed(true);
            }
          }
          }
    // eslint-disable-next-line
    }, [query, decodedTokenState, tokenFailed]);

    useEffect(() => {
      if (shipping_address) {
        currentAddressRef.current = shipping_address;
      }
    }, [shipping_address]);

    function toggleModal() {
      setShowContractModal(!showContractModal)
    }

    function toggleAgreeCheckBox() {
      setAgreeToContract(!agreeToContract)
    }

    function handleSubmission(paypalTransactionId) {
      const headers = {};
      headers['Authorization'] = 'Bearer ' + token;

      const addressToUse = currentAddressRef.current || shipping_address;

      trackEventAthelasHome("click", patientID, "athelas_home_onboarding", "agree_to_contract");

      axios.post(
        `${API_SERVER_URL}/ggp_prospective_patient/complete_onboarding_for_ggp_patient`,
        {
          patient_id: patientID,
          paypal_transaction_id: paypalTransactionId,
          num_devices: numDevicesRef.current,
          num_strips: numStripsRef.current,
          total_price: (numDevicesRef.current * devicePriceRef.current) + (numStripsRef.current * stripPriceRef.current),
          shipping_address: addressToUse,
          first_name: allDetails?.first_name || '',
          last_name: allDetails?.last_name || '',
          email: allDetails?.email || userEmail
        },
        {
          headers,
        }
      )
      .then((response) => {
        setBackendMessages({
          message: response.data.message,
          associationsCreated: response.data.associations_created,
          associationMessages: response.data.association_messages
        });
        setIsSubmittedSuccessfully(true);
      })
      .catch((error) => {
        setHasBackendError(true);
        alert("There was an issue placing your order. Please contact Athelas Support.");
        setIsSubmittedSuccessfully(true);
      });
    }

    const contractModal = () => {
        const header = `Athelas Home Service Agreement`;
        return (
          <div>
            <Modal
              isOpen={showContractModal}
              onAfterOpen={() => {}}
              onRequestClose={toggleModal}
              style={modalStyles}
              contentLabel="Contract Modal"
            >
              <button onClick = {toggleModal} style = {{float: 'right'}}>X</button>
              <h5>{header}</h5>
              <Contract 
                allDetails={allDetails}
              />
            </Modal>
          </div>
        );
      };

      const onError = (data, actions) => {
        setErrorMessagePaypal("An Error occured with during payment. Please contact Athelas Support")
        alert("An Error occured with during the payment transaction. If the error persists, please contact Athelas Support.")
      };

      const buildPaypalPurchaseUnitsPayload = () => {
        
        const user_name = userEmail.substring(0, userEmail.lastIndexOf("@"));
        let all_items = [
          {
            "name": "Athelas Test Strips", /* Shows within upper-right dropdown during payment approval */
            "description": "", /* Item details will also be in the completed paypal.com transaction view */
            "unit_amount": {
              "currency_code": "USD",
              "value":  stripPriceRef.current+ ""
            },
            "quantity": numStripsRef.current + ""
          },

          {
            "name": "Athelas Home Device", /* Shows within upper-right dropdown during payment approval */
            "description": "", /* Item details will also be in the completed paypal.com transaction view */
            "unit_amount": {
              "currency_code": "USD",
              "value": devicePriceRef.current + ""
            },
            "quantity": numDevicesRef.current + ""
          }
        ]
        return [
          {
            "description": `[Athelas Home] Order for ${user_name}`,
            "amount": {
              "currency_code": "USD",
              "value": ((numDevicesRef.current*devicePriceRef.current) + (numStripsRef.current*stripPriceRef.current))+ "",
              "breakdown": {
                "item_total": {  /* Required when including the items array */
                  "currency_code": "USD",
                  "value": ((numDevicesRef.current*devicePriceRef.current) + (numStripsRef.current*stripPriceRef.current))+ ""
                }
              }
            },
            "items": all_items
          }
        ]
      }

      const createOrder = (data, actions) => {
        const purchase_units_load = buildPaypalPurchaseUnitsPayload()
        return actions.order
          .create({
            purchase_units: purchase_units_load,
            // not needed if a shipping address is actually needed
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
          })
          .then((orderID) => {
            setOrderIDPaypal(orderID)
            return orderID;
          });
      };

      const onApprove = (data, actions) => {    
        return actions.order.capture().then((details) => {
          handleSubmission(details.purchase_units[0].payments.captures[0].id);
        });
      };

      const handleAddressUpdate = async (e) => {
        e.preventDefault();
        setAddressVerificationError("");
        setIsVerifyingAddress(true);
        
        try {
          const headers = { 'Authorization': 'Bearer ' + token };
          const response = await axios.post(
            `${API_SERVER_URL}/ggp_prospective_patient/${patientID}/update_shipping_address`,
            { shipping_address: addressFields },
            { headers }
          );
          
          if (response.status === 200) {
            setShippingAddress(addressFields);
            currentAddressRef.current = addressFields;
            setIsEditingAddress(false);
            setAddressUpdateSuccess(true);
            setTimeout(() => {
              setAddressUpdateSuccess(false);
            }, 3000);
          }
        } catch (error) {
          if (error.response?.status === 400) {
            setAddressVerificationError(error.response.data.message);
          } else {
            setAddressVerificationError("Failed to update address. Please try again.");
          }
        } finally {
          setIsVerifyingAddress(false);
        }
      };

      const parseAddressString = (addressStr) => {
        if (!addressStr) return {
          address_line1: '',
          address_line2: '',
          city_locality: '',
          state_province: '',
          postal_code: '',
          country_code: 'US'
        };

        try {
          const parts = addressStr.split(',').map(part => part.trim());
          
          // Extract postal code from the last part
          const lastPart = parts[parts.length - 1];
          const postalCode = lastPart.match(/\d{5}/)?.[0] || '';
          
          // Remove "United States" and postal code from parts
          const cleanParts = parts.filter(part => 
            !part.includes('United States') && 
            !part.match(/^\d{5}$/)
          );

          return {
            address_line1: cleanParts[0] || '',
            address_line2: cleanParts[1] || '',
            city_locality: cleanParts[2] || '',
            state_province: cleanParts[3] || '',
            postal_code: postalCode,
            country_code: 'US'
          };
        } catch (error) {
          console.error('Error parsing address:', error);
          return {
            address_line1: addressStr,
            address_line2: '',
            city_locality: '',
            state_province: '',
            postal_code: '',
            country_code: 'US'
          };
        }
      };

      return tokenFailed ? (
        <Center>Your onboarding session has expired.<br />If you have any questions, please contact Athelas Support.</Center>
      ) : isSubmittedSuccessfully ? (
        <Center>
          <h2 style={{ color: '#D32F2F', marginBottom: '20px' }}>Welcome to the Athelas Community! 🎉</h2>
          <div style={{ 
            backgroundColor: '#FFEBEE', 
            padding: '25px', 
            borderRadius: '10px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}>
            <h3 style={{ color: '#B71C1C', marginBottom: '15px' }}>Your Payment Has Been Received</h3>
            <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#C62828' }}>
              Thank you for choosing Athelas! Your onboarding is now complete, and we're excited to have you join our community. 
              <br /><br />
              {hasBackendError ? 
                "There was an issue processing your order on our end. Our support team has been notified and will reach out to you shortly." :
                "We will reach out to you shortly with confirmation details and next steps."}
            </p>
          </div>
        </Center>
      ) : isLoading ? (
        <Center>Loading...</Center>
      ) : (
        <Center>
          <h2 style={{textDecoration: 'underline', textDecorationThickness: '2px'}}>Athelas Home Onboarding</h2>
          <p></p>
          <button onClick={toggleModal}>Click To Read Service Agreement</button>
          <p></p>
          <div>{contractModal()}</div>
          <Row>
            <input
                type="checkbox"
                checked={agreeToContract}
                onChange={toggleAgreeCheckBox}
            />
            <p> Check here to indicate you have read and agree to the Service Agreement and the Athelas Terms of Service detailed above.</p>
          </Row>
          <hr></hr>
          <h4 style={{textDecoration: 'underline'}}>First Order Details</h4>
          <table className={`table table-bordered`}>
            <thead className='thead-light'>
              <tr>
                <th></th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Devices</th>
                <td>{numDevices || 0}</td>
                <td>${(devicePrice || 0).toFixed(2)}</td>
                <td>${((numDevices || 0) * (devicePrice || 0)).toFixed(2)}</td>
              </tr>
              <tr>
                <th scope="row">Strips</th>
                <td>{numStrips || 0}</td>
                <td>${(stripPrice || 0).toFixed(2)}</td>
                <td>${((numStrips || 0) * (stripPrice || 0)).toFixed(2)}</td>
              </tr>
              <tr>
                <th scope="row">Order Total</th>
                <td>-</td>
                <td>-</td>
                <td>${(
                  ((numDevices || 0) * (devicePrice || 0)) + 
                  ((numStrips || 0) * (stripPrice || 0))
                ).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
          <p></p>
          <ShippingAddressSection>
            <h4 style={{textDecoration: 'underline'}}>Shipping Address</h4>
            {isEditingAddress ? (
              <AddressForm onSubmit={handleAddressUpdate}>
                <AddressInput
                  placeholder="Address Line 1"
                  value={addressFields.address_line1}
                  onChange={(e) => setAddressFields({...addressFields, address_line1: e.target.value})}
                  required
                />
                <AddressInput
                  placeholder="Address Line 2 (Optional)"
                  value={addressFields.address_line2}
                  onChange={(e) => setAddressFields({...addressFields, address_line2: e.target.value})}
                />
                <AddressInput
                  placeholder="City"
                  value={addressFields.city_locality}
                  onChange={(e) => setAddressFields({...addressFields, city_locality: e.target.value})}
                  required
                />
                <AddressInput
                  placeholder="State"
                  value={addressFields.state_province}
                  onChange={(e) => setAddressFields({...addressFields, state_province: e.target.value})}
                  required
                />
                <AddressInput
                  placeholder="Postal Code"
                  value={addressFields.postal_code}
                  onChange={(e) => setAddressFields({...addressFields, postal_code: e.target.value})}
                  required
                />
                <div>
                  <button type="submit" disabled={isVerifyingAddress}>
                    {isVerifyingAddress ? "Verifying..." : "Save"}
                  </button>
                  <button type="button" onClick={() => {
                    setIsEditingAddress(false);
                    setAddressVerificationError("");
                  }}>Cancel</button>
                </div>
                {addressVerificationError && (
                  <ErrorMessage>{addressVerificationError}</ErrorMessage>
                )}
              </AddressForm>
            ) : (
              <AddressContainer>
                {shipping_address ? (
                  <>
                    {typeof shipping_address === 'string' ? shipping_address : (
                      <>
                        {shipping_address.address_line1}<br />
                        {shipping_address.address_line2 && <>{shipping_address.address_line2}<br /></>}
                        {shipping_address.city_locality}, {shipping_address.state_province} {shipping_address.postal_code}
                      </>
                    )}
                    <EditButton onClick={() => {
                      setAddressFields(typeof shipping_address === 'string' 
                        ? parseAddressString(shipping_address) 
                        : shipping_address
                      );
                      setIsEditingAddress(true);
                    }}>
                      Edit
                    </EditButton>
                  </>
                ) : (
                  "Not Provided"
                )}
              </AddressContainer>
            )}
          </ShippingAddressSection>
  
          <p></p>
          <PayPalScriptProvider options={{ "client-id": GGP_PAYPAL_CLIENT_ID, "disable-funding": 'paylater' }}>
            {paymentMade ? (
              <Alert severity="info" sx={{ 
                justifyContent: 'center',
                '& .MuiAlert-message': {
                  textAlign: 'center',
                  width: '100%'
                }
              }}>
                Payment for this patient has already been completed.
              </Alert>
            ) : (
              <PayPalButtons 
                style={{ layout: "horizontal" }} 
                disabled={!agreeToContract || showContractModal || paypalButtonHidden} 
                createOrder={createOrder} 
                onApprove={onApprove} 
                onError={onError}
              />
            )}
          </PayPalScriptProvider>
          <SuccessMessage show={addressUpdateSuccess}>
            <CheckIcon>✓</CheckIcon>
            Address updated successfully
          </SuccessMessage>
        </Center>
      );
  }

const Center = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  margin-right: 30%; 
  margin-left: 30%;
  margin-bottom: 5%;
`;

const Row = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ShippingAddressSection = styled.div`
`;

const AddressContainer = styled.div`
  font-size: 14px;
  color: #333;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 10px
  line-height: 1.5;
`;

const EditButton = styled.button`
  padding: 5px 10px;
  margin-left: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const AddressForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const AddressInput = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const SuccessMessage = styled.div`
  color: #4caf50;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  height: ${props => props.show ? 'auto' : '0'};
  overflow: hidden;
`;

const CheckIcon = styled.span`
  margin-right: 8px;
  font-size: 16px;
`;

const ErrorMessage = styled.div`
  color: #d32f2f;
  font-size: 14px;
  margin-top: 8px;
  padding: 8px;
  background-color: #ffebee;
  border-radius: 4px;
  text-align: left;
  white-space: pre-line;
`;

export default AthelasHomeOnboarding;