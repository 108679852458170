import React from "react";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import { themeOptions } from "../PatientEnrollment/ThemeOptionsPatientEnrollment";
import rtmBanner from "./assets/rtm_banner.png";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  Button,
  FormControlLabel,
  Grid,
  Checkbox,
  FormGroup,
  FormHelperText,
  Typography,
  CardMedia,
  Alert,
} from "@mui/material";
import jwt from "jsonwebtoken";
import InputMask from "react-input-mask";
import { patientSchema } from "../RtmConsent/PatientValidationSchema.ts";
import Box from "@mui/material/Box";
import { PUBLIC_KEY } from "../../app/constants";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import BeatLoader from "react-spinners/BeatLoader";
import { markPatientConsent } from "./RtmConsentAPI.ts";
import Modal from "react-modal";
import { RtmContract } from "./RtmContract";
import html2pdf from 'html2pdf.js';

export default function RtmConsent() {
  console.log("RtmConsent component mounted");

  const [searchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const [patientId, setPatientId] = useState(0);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [tokenFailed, setTokenFailed] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isSubmitted, isValid }
  } = useForm({ 
    resolver: yupResolver(patientSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      patient_consent: false,
      email: '',
      phone_number: ''
    }
  });
  const [showContractModal, setShowContractModal] = useState(false);
  const [jwtLoaded, setJwtLoaded] = useState(false);
  const [pdfError, setPdfError] = useState(false);

  useEffect(() => {
    try {
      const tokenFromURL = searchParams.get("token");
      if (!tokenFromURL) {
        setTokenFailed(true);
        return;
      }

      const decodedToken = jwt.verify(tokenFromURL, PUBLIC_KEY, {
        algorithms: ["RS256"],
      });

      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.exp && decodedToken.exp < currentTime) {
        setTokenFailed(true);
        return;
      }

      setToken(tokenFromURL);
      setPatientId(decodedToken.patient_id);
      setJwtLoaded(true);
      
      reset({
        first_name: decodedToken.patient_first_name,
        last_name: decodedToken.patient_last_name,
        date_of_birth: decodedToken.patient_dob,
        phone_number: decodedToken.patient_phone_number || '',
        email: decodedToken.patient_email || '',
      });
    } catch (e) {
      console.error("Token validation failed:", e);
      setTokenFailed(true);
      setJwtLoaded(false);
    }
  }, [searchParams, reset]);

  const watchFirstName = watch("first_name");
  const watchLastName = watch("last_name");

  const theme = createTheme(themeOptions);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsLoadingResponse(true);
      setPdfError(false);

      const formData = new FormData();
      formData.append("patient_id", patientId);
      
      const formElement = document.getElementById("rtm-consent-form");
      const pdf = await html2pdf()
        .from(formElement)
        .outputPdf('blob');
      
      formData.append("completed_form_pdf", pdf, "rtm_consent.pdf");
      
      const response = await markPatientConsent(formData);

      if (response.data.patient_consent_validated) {
        navigate("/rtm_consent/confirmation", {
          state: {
            id: patientId,
            firstName: watchFirstName,
            lastName: watchLastName,
            enrolled: true,
            token: token
          }
        });
      }
    } catch (error) {
      setPdfError(true);
    } finally {
      setIsLoadingResponse(false);
    }
  });

  const toggleModal = (e) => {
    e.preventDefault();
    setShowContractModal(!showContractModal);
  };

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    content: {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '700px',
      width: '95%',
      height: '80vh',
      margin: '20px auto',
      padding: '25px',
      backgroundColor: 'white',
      borderRadius: '8px',
      zIndex: 1001,
      fontSize: '14px',
      lineHeight: '1.4'
    }
  };

  const contractModal = () => {
    const header = `Remote Therapeutic Monitoring Consent`;
    return (
      <div>
        <Modal
          isOpen={showContractModal}
          onAfterOpen={() => {}}
          onRequestClose={toggleModal}
          style={modalStyles}
          contentLabel="Contract Modal"
        >
          <button onClick={toggleModal} style={{ float: 'right' }}>X</button>
          <h5>{header}</h5>
          <RtmContract 
            allDetails={null}  // Pass relevant details if needed
          />
        </Modal>
      </div>
    );
  };

  // Show error message if token is invalid
  if (tokenFailed) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h5" color="error" gutterBottom>
          Your consent session has expired.
        </Typography>
        <Typography>
          If you have any questions, please contact your healthcare provider.
        </Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CardMedia
        sx={{ width: 1, height: 1 / 5 }}
        src={rtmBanner}
        component="img"
      />
      <Box sx={{ mx: 8, mb: 8, mt: 2 }}>
        <form 
          id="rtm-consent-form" 
          onSubmit={onSubmit}
          noValidate
        >
          <Grid
            style={{ minHeight: "40vh" }}
            alignItems="center"
            justifyContent="flex-start"
            container
            direction="row"
            align="stretch"
            spacing={2}
          >
            <Grid item xs={12}>
              <Box sx={{ display: "flex", mb: 1 }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Hello, &nbsp;
                </Typography>
                <Typography variant="body1">
                  {watchFirstName} {watchLastName}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">01 Patient Info</Typography>
            </Grid>

            <Grid item xs={6} md={4} container flexGrow direction="column">
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Patient First Name"
                    fullWidth
                    disabled={true}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow direction="column">
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Patient Last Name"
                    disabled={true}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow direction="column">
              <Controller
                name="date_of_birth"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label="Patient Date of Birth"
                    fullWidth
                    disabled={true}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6} container flexGrow direction="column">
              <Controller
                name="phone_number"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <InputMask
                    mask="9999999999"
                    value={value}
                    onChange={onChange}
                  >
                    {(inputProps) => (
                      <TextField
                        label="Patient Phone Number"
                        type="text"
                        inputRef={ref}
                        sx={{ flex: 1 }}
                        error={!!(errors.phone_number && isSubmitted)}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
              {errors.phone_number && isSubmitted && (
                <FormHelperText error sx={{ ml: 2 }}>
                  {errors.phone_number.message}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6} md={6} container flexGrow direction="column">
              <Controller
                name="email"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label="Patient Email"
                    inputRef={ref}
                    sx={{ flex: 1 }}
                    error={!!(errors.email && isSubmitted)}
                    inputProps={{ maxLength: 80 }}
                  />
                )}
              />
              {errors.email && isSubmitted && (
                <FormHelperText error sx={{ ml: 2 }}>
                  {errors.email.message}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">02 Patient Consent</Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="patient_consent"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormGroup>
                    <FormControlLabel
                      sx={{ alignItems: "flex-start" }}
                      control={
                        <Checkbox
                          {...field}
                          sx={{
                            marginTop: -1,
                          }}
                        />
                      }
                      label={
                        <p>
                          By clicking 'Submit,' I, {watchFirstName} {watchLastName}, consent to receive remote monitoring
                          services provided by Athelas under the direction and supervision of my healthcare provider. 
                          I consent to the terms set forth in the{" "}
                          <button
                            onClick={toggleModal}
                            type="button"
                            style={{
                              background: 'none',
                              border: 'none',
                              padding: 0,
                              color: 'blue',
                              textDecoration: 'underline',
                              cursor: 'pointer'
                            }}
                          >
                            patient consent form
                          </button>
                          . Depending on your insurance, there may be a monthly copay associated with enrollment 
                          in this program. I agree to receive communications by text message. Standard message 
                          rates may apply.You can reply STOP
                          to opt-out or HELP for more information. You may
                          review our{" "}
                          <a href="https://www.athelas.com/privacy-policy">
                            Privacy Policy
                          </a>{" "}
                          to learn how your data is used.
                        </p>
                      }
                    />
                    {errors.patient_consent && (
                      <FormHelperText error>
                        {errors.patient_consent.message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                )}
              />
            </Grid>
            {/* Show validation errors if any */}
            {Object.keys(errors).length > 0 && (
              <Grid item xs={12}>
                <Alert severity="error" sx={{ mb: 2 }}>
                  <Typography variant="body1">
                    Please complete all required fields:
                    <ul>
                      {Object.entries(errors).map(([field, error]) => (
                        <li key={field}>{error.message}</li>
                      ))}
                    </ul>
                  </Typography>
                </Alert>
              </Grid>
            )}
            {pdfError && (
              <Grid item xs={12}>
                <Alert severity="error" sx={{ mb: 2 }}>
                  <Typography variant="body1">
                    We've detected an issue with completing your consent submission. Please try:
                    <ol>
                      <li>Refreshing the page</li>
                      <li>Filling out the form again</li>
                      <li>If the issue persists, please contact your healthcare provider</li>
                    </ol>
                  </Typography>
                </Alert>
              </Grid>
            )}
            {isLoadingResponse && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <BeatLoader color="#5A67E5" />
                <p>Processing Consent Form</p>
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                type="submit"
                disabled={isLoadingResponse}
                style={{ minWidth: "30vw", textAlign: "center" }}
              >
                Submit Consent Form
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      {contractModal()}
    </ThemeProvider>
  );
}
