import React from "react";
import { createTheme } from "@mui/material/styles";
import { themeOptions } from "../PatientEnrollment/ThemeOptionsPatientEnrollment";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ThemeProvider,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export default function RtmConsentConfirmation() {
  const navigate = useNavigate();
  const location = useLocation();

  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <div style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}>
            <CheckIcon color="success" />
            <Typography align="center" variant="h5" gutterBottom>
              RTM Consent Form Successfully Submitted!
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="body1"
            sx={{ mx: 8, mb: 2, color: "text.secondary" }}
            gutterBottom
          >
            Thank you for your submission. You will receive a confirmation email shortly.
          </Typography>
        </Grid>
        <Grid minWidth="50vw" item xs={12}>
          <Card raised>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Name:
                </Typography>
                <Typography variant="body1">
                  {location.state?.firstName} {location.state?.lastName}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
